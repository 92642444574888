body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html{

  scroll-behavior: smooth;

}

code {
  font-family: "Nunito", sans-serif;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
#coloredImage {
  background: radial-gradient(rgb(0, 0, 0) 5%, rgb(43, 43, 43) 15%, rgb(65, 65, 65) 60%);
}
@keyframes float {
  0% {
      transform: translateY(0px);
  }
  50% {
      transform: translateY(-20px);
  }
  100% {
      transform: translateY(0px);
  }
}

.floating {
  box-sizing: border-box;
  overflow: hidden;
 filter:drop-shadow(10px 10px 10px rgb(0, 0, 0));
  transform: translateY(0px);
  animation: float 6s ease-in-out infinite;
}
